import React from "react" // eslint-disable-line no-unused-vars
import PropTypes from "prop-types"
import { useNotationGroup } from "./notation-group"

const NotationHidden = (props) => {
  const { children, id } = props
  const { state } = useNotationGroup()
  const isHidden = state?.[id]?.active

  if (isHidden) {
    return null
  }

  return children
}

NotationHidden.propTypes = {
  id: PropTypes.string.isRequired,
}

export default NotationHidden
